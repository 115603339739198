import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { Firebase } from '@ionic-native/firebase/ngx';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { SpeakerPopupPage } from './speaker-popup/speaker-popup.page';
import { QuestionPopupPage } from './question-popup/question-popup.page';
import { FeedbackPopupPage } from './feedback-popup/feedback-popup.page';
import { Calendar } from '@ionic-native/calendar/ngx';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicStorageModule } from '@ionic/storage';
import { ExpandableComponent } from './expandable/expandable.component';
import { Push } from '@ionic-native/push/ngx';
import { NewsPopupPage } from './news-popup/news-popup.page';
import { ProgramdetailPopupPage } from './programdetail-popup/programdetail-popup.page';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { RoomPopupPage } from './room-popup/room-popup.page';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { FcmProvider } from './provider.service';


@NgModule({
  declarations: [AppComponent, routingComponents, SpeakerPopupPage, QuestionPopupPage, ExpandableComponent, NewsPopupPage,
    ProgramdetailPopupPage, RoomPopupPage, FeedbackPopupPage],
  entryComponents: [SpeakerPopupPage, QuestionPopupPage, NewsPopupPage, ProgramdetailPopupPage, RoomPopupPage, FeedbackPopupPage],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFirestoreModule.enablePersistence(),
    AngularFireModule.initializeApp(environment.firebaseConfig), // imports firebase/app needed for everything
    FormsModule,
    ReactiveFormsModule,
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,],
    IonicStorageModule.forRoot(),
  ],
  providers: [
    StatusBar,
    Calendar,
    BarcodeScanner,
    SplashScreen,
    Firebase,
    FcmProvider,
    InAppBrowser,
    LocalNotifications,
    Push,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],

  bootstrap: [AppComponent]
})
export class AppModule {
}
