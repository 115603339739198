import { Component, OnInit, Input } from '@angular/core';
import { Speaker } from '../speaker';
import { NavParams, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-speaker-popup',
  templateUrl: './speaker-popup.page.html',
  styleUrls: ['./speaker-popup.page.scss']
})
export class SpeakerPopupPage implements OnInit {
  // Data passed in from Program Component
  @Input() focusedSpeaker: Speaker;
  speaker: Speaker;

  ngOnInit() {
  }

  constructor(
    navParams: NavParams,
    private modalController: ModalController,
  ) {
    this.speaker = new Speaker();
    this.speaker = JSON.parse(JSON.stringify(navParams.data));
  }

  cancel() {
    this.modalController.dismiss({
      dismissed : true
    });
  }



}
