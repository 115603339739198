import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ProgramPage } from './program/program.page';
import { NewsPage } from './news/news.page';
import { FaqPage } from './faq/faq.page';
import { RoomPage } from './room/room.page';
import { PartnerPage } from './partner/partner.page';
import { DataService } from './data.service';

const routes: Routes = [
  { path: '', loadChildren: './tabs/tabs.module#TabsPageModule' },
  { path: 'program', component: ProgramPage },
  { path: 'news', component: NewsPage },
  { path: 'faq', component: FaqPage },
  { path: 'room', component: RoomPage },
  { path: 'partner', component: PartnerPage },
  { path: 'speaker-popup', loadChildren: './speaker-popup/speaker-popup.module#SpeakerPopupPageModule' },
  { path: 'question-popup', loadChildren: './question-popup/question-popup.module#QuestionPopupPageModule' },
  { path: 'programdetail-popup', loadChildren: './programdetail-popup/programdetail-popup.module#ProgramdetailPopupPageModule' },
  { path: 'news-popup', loadChildren: './news-popup/news-popup.module#NewsPopupPageModule' },
  { path: 'room-popup', loadChildren: './room-popup/room-popup.module#RoomPopupPageModule' },
  { path: 'feedback-popup', loadChildren: './feedback-popup/feedback-popup.module#FeedbackPopupPageModule' },
  { path: 'ticket', loadChildren: './ticket/ticket.module#TicketPageModule' },
  { path: 'admin', loadChildren: './admin/admin.module#AdminPageModule' },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
  providers: [DataService]
})
export class AppRoutingModule { }
export const routingComponents = [ProgramPage, NewsPage, FaqPage, RoomPage, PartnerPage];
