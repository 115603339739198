import { Component, OnInit, Input } from '@angular/core';
import { Discussions } from '../discussions';
import { NavParams, ModalController } from '@ionic/angular';
import { DataService } from '../data.service';
import { ToastController } from '@ionic/angular';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-feedback-popup',
  templateUrl: './feedback-popup.page.html',
  styleUrls: ['./feedback-popup.page.scss'],
})
export class FeedbackPopupPage implements OnInit {
  feedbackForm = this.formBuilder.group({
    sicherheitscode: ['', [Validators.required, Validators.maxLength(10)]],
  });

  public errorMessages = {
    sicherheitscode: [
      { type: 'required', message: 'Bitte den veröffentlichten Sicherheitscode angeben' },
      { type: 'maxlength', message: 'Der Sicherheitscode ist nicht länger als 10 Zeichen' }
    ]
  };

  discussion: Discussions;
  captchaCode: number;
  questionContent: string;
  alreadyVoted = false;

  async ngOnInit() {
    let currentVotes = [];
    currentVotes = await this.service.getStoredItem('votes');
    if (currentVotes === undefined || currentVotes === null) {
      currentVotes = [];
    }
    if (currentVotes.includes(this.discussion.Titel)) {
      this.alreadyVoted = true;
      this.service.presentResult('Für dieses Event haben wir Ihr Feedback bereits', 'warning', 'middle');
    }
  }

  constructor(
    public navParams: NavParams,
    private modalController: ModalController,
    private service: DataService,
    public toastCtrl: ToastController,
    public formBuilder: FormBuilder,
  ) {
    this.discussion = new Discussions();
    this.discussion = JSON.parse(JSON.stringify(navParams.data));
  }

  onGiveFeedback(points: number) {
    /*if (this.checkCaptcha(this.captchaCode, this.discussion.captcha) === true) {*/
      this.service.sendFeedback(points, this.discussion.Titel).then(async res => {
        this.service.presentResult('Das Feedback wurde erfolgreich verschickt', 'success', 'middle');
        //this.resetFields();
        let currentVotes = await this.service.getStoredItem('votes');
        if (currentVotes === undefined || currentVotes === null) {
          currentVotes = [];
        }
        currentVotes.push(this.discussion.Titel);
        this.service.setStoredItem('votes', currentVotes);
        this.cancel();
      }).catch(err => {
        this.service.presentResult('Ein Fehler ist aufgetreten', 'danger', 'middle');
      });
    }// else {
      //this.service.presentResult('Ungültiger Sicherheitscode', 'danger', 'middle');
    //}
  /*}*/

 /* checkCaptcha(enteredCaptcha: number, requiredCaptcha: number) {
    return (enteredCaptcha === requiredCaptcha ? true : false);
  }

  resetFields() {
    this.captchaCode = null;
  }

  get sicherheitscode() {
    return this.feedbackForm.get('sicherheitscode');
  }*/

  cancel() {
    this.modalController.dismiss({
      dismissed: true
    });
  }


}
