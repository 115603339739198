export class Discussions {
    public id: string;
    public Titel: string;
    public Beschreibung: string;
    public Bild: string;
    public Speaker: Array<any>;
    public captcha: number;
    public Datum: any;
    public Room: string;
    public isCurrentlyFav: boolean;
    public Day: string;
    public Uhrzeit: string;
}
