import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Storage } from '@ionic/storage';
import { ToastController } from '@ionic/angular';
import { Discussions } from './discussions';

require('firebase/auth');
require('firebase/firestore');
require('firebase/database');

@Injectable({
  providedIn: 'root'
})
export class DataService {
  constructor(
    private firestore: AngularFirestore,
    private storage: Storage,
    private toastCtrl: ToastController) { }

  getDiscussions() {
    return this.firestore.collection('Hauptprogramm').snapshotChanges();
  }

  getWorkshops() {
    return this.firestore.collection('workshops').snapshotChanges();
  }

  getNews() {
    return this.firestore.collection('news').snapshotChanges();
  }

  getRooms() {
    return this.firestore.collection('rooms').snapshotChanges();
  }

  getParticipants() {
    return this.firestore.collection('participants').snapshotChanges();
  }

  getPartners() {
    return this.firestore.collection('partner').snapshotChanges();
  }

  setStoredItem(id: string, value: any) {
    this.storage.set(id, value);
  }

  getStoredItem(id: string) {
    return this.storage.get(id);
  }

  clearStorage() {
    this.storage.clear();
  }

  addQuestion(questionText, programInfo) {
    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('/questions').add({
        question: questionText,
        program: programInfo
      })
        .then(
          (res) => {
            resolve(true);
          },
          err => reject(err)
        );
    });
  }

  getFeedbackByDiscussion(discussionTitel) {
    return this.firestore.collection('feedback', ref => ref.where('programm', '==', discussionTitel)).snapshotChanges();
  }

  async sendFeedback(points, discussionTitel) {
    let feedback = [];
    let wasThereBefore = true;
    const that = this;
    const promise = new Promise((resolve, reject) => {
      that.getFeedbackByDiscussion(discussionTitel).subscribe(actionArray => {
        feedback = actionArray.map(item => {
          return {
            id: actionArray[0].payload.doc.id,
            ...(item.payload.doc.data() as object)
          };
        });
        if (feedback === undefined || feedback.length < 1) {
          feedback = [{
            programm: discussionTitel,
            votes_bad: 0,
            votes_avg: 0,
            votes_good: 0
          }];
          wasThereBefore = false;
        }

        resolve(feedback);
      });
    });

    promise.then(existFeedback => {
      // Adjust votes (rating)
      switch (points) {
        case 0:
          existFeedback[0].votes_bad += 1;
          break;
        case 1:
          existFeedback[0].votes_avg += 1;
          break;
        case 2:
          existFeedback[0].votes_good += 1;
          break;
      }
      // Update
      if (wasThereBefore) {
        return this.firestore.collection('feedback').doc(existFeedback[0].id).update(existFeedback[0]);
      } else {
        this.firestore.collection('feedback').add(existFeedback[0]);
      }
    });
  }

  getFAQs() {
    return this.firestore.collection('FAQ').snapshotChanges();
  }

  saveProgramm(eventType, programm) {
    return new Promise<any>((resolve, reject) => {
      this.firestore.collection(eventType).add({ ...programm })
        .then(
          (res) => {
            resolve(true);
          },
          err => reject(err)
        );
    });
  }

  saveRoom(room) {
    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('rooms').add({ ...room })
        .then(
          (res) => {
            resolve(true);
          },
          err => reject(err)
        );
    });
  }

  saveFaq(faq) {
    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('FAQ').add({ ...faq })
        .then(
          (res) => {
            resolve(true);
          },
          err => reject(err)
        );
    });
  }

  saveNews(news) {
    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('news').add({ ...news })
        .then(
          (res) => {
            resolve(true);
          },
          err => reject(err)
        );
    });
  }

  savePartner(partner) {
    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('partner').add({ ...partner })
        .then(
          (res) => {
            resolve(true);
          },
          err => reject(err)
        );
    });
  }

  presentResult(text, colorKind, whereShow) {
    this.toastCtrl.create({
      message: text,
      duration: 3000,
      position: whereShow,
      color: colorKind
    }).then(toast => {
      toast.present();
    });
  }
}
