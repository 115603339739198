// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
const firebase = require('firebase/app');

// Add the Firebase products that you want to use
require('firebase/auth');
require('firebase/firestore');

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyCPE6N_LbwvSGv3vl5V_TvRc8v6Bc1qFKk',
    authDomain: 'mafoapp-31cdc.firebaseapp.com',
    databaseURL: 'https://mafoapp-31cdc.firebaseio.com',
    projectId: 'mafoapp-31cdc',
    storageBucket: 'mafoapp-31cdc.appspot.com',
    messagingSenderId: '528063448295',
    /* LOCAL */
    // appId: "1:533057048187:web:c476c23d3f34cf75",
    /* WEB APP */
    appId: '1:528063448295:web:0d00f707dfebff1b06ce42',
    measurementId: 'G-WP8NX47PQQ'
  }
};
  // Initialize Firebase
firebase.initializeApp(environment.firebaseConfig);

