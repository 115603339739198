import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.page.html',
  styleUrls: ['./partner.page.scss'],
})

export class PartnerPage implements OnInit {
  partners: Array<any>;

  constructor(
    private service: DataService
  ) { }


  ngOnInit() {
    this.service.getPartners().subscribe(actionArray => {
      this.partners = actionArray.map(item => {
        return {
          ...(item.payload.doc.data() as object)
        };
      });
    }
    );
  }

}
