import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Push, PushObject, PushOptions } from '@ionic-native/push/ngx';
import { NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';

import { FcmProvider } from './provider.service';

import { ToastController } from '@ionic/angular';
import { Subject } from 'rxjs/Subject';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  constructor(
    private platform: Platform,
    fcm: FcmProvider,
    toastCtrl: ToastController,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private push: Push,
    public navCtrl: NavController,
    private storage: Storage,
  ) 
  {
   
    platform.ready().then(() => {
      this.storage.set('schedule', []);

      // Get a FCM token
      fcm.getToken()

      // Listen to incoming messages
      fcm.listenToNotifications().pipe(
        tap(msg => {
          // show a toast
          this.showtoast(msg);
           
        })
      )
      .subscribe()
  })}
toastCtrl: ToastController;
  async showtoast(msg) {
    const toast = await this.toastCtrl.create({
      message: msg.body,
      duration: 3000
    });
    toast.present();
  }
  }

 
    //this.initializeApp();
  




 /* initializeApp() {
      this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.pushSetup();
    });
    
  }

  pushSetup(){
      // to initialize push notifications

const options: PushOptions = {
  android: {
    senderID: '533057048187'
  },
  ios: {
      alert: 'true',
      badge: true,
      sound: 'false'
  }
}

const pushObject: PushObject = this.push.init(options);



pushObject.on('notification').subscribe((notification: any) => console.log('Received a notification', notification));

pushObject.on('registration').subscribe((registration: any) => console.log('Device registered', registration));

pushObject.on('error').subscribe(error => console.error('Error with Push plugin', error));
  }
}*/