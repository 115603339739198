import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { FAQ } from '../faq';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.page.html',
  styleUrls: ['./faq.page.scss'],
  providers: [
    DataService,
  ]
})
export class FaqPage implements OnInit {
  public faqs: Array<any>;

  constructor(
    private service: DataService
  ) { }

  ngOnInit() {
    this.service.getFAQs().subscribe(actionArray => {
      this.faqs = actionArray.map(item => {
        return {
          ...(item.payload.doc.data() as object)
        } as FAQ;
      });

      this.faqs.forEach(element => {
        element.expanded = false;
      });
    });
  }

  expandItem(item): void {
    if (item.expanded) {
      item.expanded = false;
    } else {
      this.faqs.map(listItem => {
        if (item === listItem) {
          listItem.expanded = !listItem.expanded;
        } else {
          listItem.expanded = false;
        }
        return listItem;
      });
    }
  }
}
