import { Component, OnInit, Input } from '@angular/core';
import { News } from '../news';
import { NavParams, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-news-popup',
  templateUrl: './news-popup.page.html',
  styleUrls: ['./news-popup.page.scss']
})
export class NewsPopupPage implements OnInit {
    // Data passed in from News Component
    @Input() focusedNews: News;
    news: News;

  ngOnInit() {
  }

  constructor(
    navParams: NavParams,
    private modalController: ModalController,
  ) {
    this.news = new News();
    this.news = JSON.parse(JSON.stringify(navParams.data));
  }

  cancel() {
    this.modalController.dismiss({
      dismissed : true
    });
  }

}
