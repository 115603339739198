import { Component, OnInit, Input } from '@angular/core';
import { Rooms } from '../rooms';
import { Discussions } from '../discussions';
import { NavParams, ModalController } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Component({
  selector: 'app-room-popup',
  templateUrl: './room-popup.page.html',
  styleUrls: ['./room-popup.page.scss']
})
export class RoomPopupPage implements OnInit {
  constructor(
    navParams: NavParams,
    private modalController: ModalController,
    private firestore: AngularFirestore,
    private iab: InAppBrowser,
  ) {
    this.discussion = new Discussions();
    this.room = new Rooms();
    this.discussion = JSON.parse(JSON.stringify(navParams.data));
  }
  roomlist: any[];
  // Data passed in from News Component
  @Input() focusedRooms: Rooms;
  rooms: Rooms;
  discussion: Discussions;
  room: Rooms;
  filtersLoaded: Promise<boolean>;

  ngOnInit() {
    this.firestore.collection(`rooms`).valueChanges()
      .subscribe(roomlist => {
        this.roomlist = roomlist;
        this.filtersLoaded = Promise.resolve(true);
        console.log(this.roomlist);
        
        for (let key in this.roomlist) {
          console.log(this.roomlist[key].Titel);
          if (this.discussion.Room == this.roomlist[key].Titel) {
            console.log(this.roomlist[key].Titel);
            this.room = this.roomlist[key];
          }
        }
      });
  }

  cancel() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  openlink(rooms: Rooms) {
    this.iab.create(rooms.link, '_blank', 'location=yes');
  }



}
