import { Component, OnInit, Input } from '@angular/core';
import { Discussions } from '../discussions';
import { NavParams, ModalController } from '@ionic/angular';
import { DataService } from '../data.service';
import { ToastController } from '@ionic/angular';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-question-popup',
  templateUrl: './question-popup.page.html',
  styleUrls: ['./question-popup.page.scss'],

})
export class QuestionPopupPage implements OnInit {

  questionForm = this.formBuilder.group({
    sicherheitscode: ['', [Validators.required, Validators.maxLength(10)]],
    question: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(200)]]
  });

  public errorMessages = {
    sicherheitscode: [
      { type: 'required', message: 'Bitte den veröffentlichten Sicherheitscode angeben' },
      { type: 'maxlength', message: 'Der Sicherheitscode ist nicht länger als 10 Zeichen' }
    ],
    question: [
      { type: 'required', message: 'Bitte stellen Sie eine Frage' },
      { type: 'maxlength', message: 'Die Frage darf nicht länger als 200 Zeichen lang sein' },
      { type: 'minlength', message: 'Die Frage muss mindestnes 10 Zeichen lang sein' }
    ]
  };

  discussion: Discussions;
  captchaCode: number;
  questionContent: string;

  ngOnInit() {
  }

  constructor(
    public navParams: NavParams,
    private modalController: ModalController,
    private service: DataService,
    public toastCtrl: ToastController,
    public formBuilder: FormBuilder,
  ) {
    this.discussion = new Discussions();
    this.discussion = JSON.parse(JSON.stringify(navParams.data));
  }

  onSendQuestion() {
    if (this.checkCaptcha(this.captchaCode, this.discussion.captcha) === true) {
      this.service.addQuestion(this.questionContent, this.discussion.Titel).then(res => {
        this.service.presentResult('Die Frage wurde erfolgreich verschickt', 'success', 'middle');
        this.resetFields();
      }).catch(err => {
        this.service.presentResult('Ein Fehler ist aufgetreten', 'danger', 'middle');
      });
    } else {
      this.service.presentResult('Ungültiger Sicherheitscode', 'danger', 'middle');
    }
  }

  checkCaptcha(enteredCaptcha: number, requiredCaptcha: number) {
    return (enteredCaptcha === requiredCaptcha ? true : false);
  }

  resetFields() {
    this.questionContent = '';
    this.captchaCode = null;
  }

  get sicherheitscode() {
    return this.questionForm.get('sicherheitscode');
  }
  get question() {
    return this.questionForm.get('question');
  }

  cancel() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

}
