import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { Discussions } from '../discussions';
import { RoomPopupPage } from '../room-popup/room-popup.page';
import { Speaker } from '../speaker';
import { SpeakerPopupPage } from '../speaker-popup/speaker-popup.page';

@Component({
  selector: 'app-programdetail-popup',
  templateUrl: './programdetail-popup.page.html',
  styleUrls: ['./programdetail-popup.page.scss'],
})
export class ProgramdetailPopupPage implements OnInit {
  discussion: Discussions;

  constructor(
    navParams: NavParams,
    private modalController: ModalController,
  ) {
    this.discussion = new Discussions();
    this.discussion = JSON.parse(JSON.stringify(navParams.data));
  }

  ngOnInit() {
  }

  cancel() {
    this.modalController.dismiss({
      dismissed: true
    });
  }
  async onShowRoomDetails(discussion: Discussions) {
    const modal = await this.modalController.create({
      component: RoomPopupPage,
      componentProps: discussion
    });
    return await modal.present();
  }
  async onShowSpeakerDetails(speaker: Speaker) {
    const modal = await this.modalController.create({
      component: SpeakerPopupPage,
      componentProps: speaker
    });
    return await modal.present();
  }

  getFullHoursMinutesFromDate(date: Date) {
    return (date.getHours() < 10 ? '0' : '') + date.getHours() + ':' + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
  }

  transformTimestampToTime(dateInSeconds) {
    const date = new Date(0);
    date.setUTCSeconds(dateInSeconds);
    return date;
  }

}
