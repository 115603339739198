import { DataService } from '../data.service';
import { Component, OnInit } from '@angular/core';
import { Discussions } from '../discussions';
import { ViewChild } from '@angular/core';
import { IonSlides, ModalController, Events, Platform } from '@ionic/angular';
import { Speaker } from '../speaker';
import { SpeakerPopupPage } from '../speaker-popup/speaker-popup.page';
import { ProgramdetailPopupPage } from '../programdetail-popup/programdetail-popup.page';
import { RoomPopupPage } from '../room-popup/room-popup.page';
import { QuestionPopupPage } from '../question-popup/question-popup.page'; // QUESTIONS NOT USED IN NEW VERSION!!
import { FeedbackPopupPage } from '../feedback-popup/feedback-popup.page';
import { Calendar } from '@ionic-native/calendar/ngx';
import { LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { PipeTransform } from '@angular/core';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { AngularFirestore } from '@angular/fire/firestore';

require('firebase/auth');
require('firebase/firestore');
require('firebase/database');

@Component({
  selector: 'app-program',
  templateUrl: './program.page.html',
  styleUrls: ['./program.page.scss'],
  providers: [
    DataService,
  ]
})
export class ProgramPage implements OnInit, PipeTransform {
  constructor(
    private service: DataService,
    private modalController: ModalController,
    private calendar: Calendar,
    public loadingController: LoadingController,
    private router: Router,
    public events: Events,
    public navCtrl: NavController,
    private firestore: AngularFirestore,
    public platform: Platform,
    private localNotifications: LocalNotifications
  ) {
    if (this.platform.is('desktop') || this.platform.is('mobileweb')) {
      this.isApp = false;
    } else {
      this.isApp = true;
    }
    this.loadMainProgramm = true;
    this.mainContent = 'Hauptprogramm';
  }
  loadMainProgramm: any;
  loadall: any;
  raum: any;
  mainContent: string;
  myIcon = 'star-outline';
  speaker: Speaker;
  showDiscussions: boolean;
  discussions: Array<any>;
  schedule: Array<any>;
  helper: Array<any>;
  public loadedschedule: any[];
  slide = 'overview';
  slideindex;
  filtersLoaded: Promise<boolean>;
  scheduleLoaded: Promise<boolean>;
  isApp: boolean;
  slideOpts = {
    initialSlide: this.slideindex,
    speed: 100,
    spaceBetween: 10,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    ionSlideDidChange: console.log('slides loaded'),
    lazyLoading: true,
    autoHeight: true
  };

  @ViewChild(IonSlides, { static: false }) slides: IonSlides;

  async ngOnInit() {
    this.onChangeMainContent();
  }
  sortSchedule(array: Array<any>) {


    array.sort((x, y) => {
      return x.Datum.seconds - y.Datum.seconds;
    });
    array.forEach((event) => {
      event.Datum = event.Datum.seconds;
    });
    return array;
  }

  sortArray(array: Array<any>) {
    const sortedArray = ['Donnerstag', 'Freitag', 'Samstag'];
    array.forEach((day) => {
      day.value.forEach((event) => {
        event.Datum = event.Datum.seconds;
      });

      day.value.sort((x, y) => {
        return x.Datum - y.Datum;
      });

      if (day.key === 'Donnerstag') {
        sortedArray.splice(0, 1, day);
      } else if (day.key === 'Freitag') {
        sortedArray.splice(1, 1, day);
      } else if (day.key === 'Samstag') {
        sortedArray.splice(2, 1, day);
      }
      console.log(sortedArray);

    });
    return sortedArray;
  }

  async onShowSpeakerDetails(speaker: Speaker) {
    const modal = await this.modalController.create({
      component: SpeakerPopupPage,
      componentProps: speaker
    });
    return await modal.present();
  }

  async onShowDiscussionDetails(discussion: Discussions) {
    const modal = await this.modalController.create({
      component: ProgramdetailPopupPage,
      componentProps: discussion
    });
    return await modal.present();
  }

  async onAskQuestion(discussion: Discussions) { // NOT USED
    const modal = await this.modalController.create({
      component: QuestionPopupPage,
      componentProps: discussion
    });
    return await modal.present();
  }

  async onGiveFeedback(discussion: Discussions) {
    const modal = await this.modalController.create({
      component: FeedbackPopupPage,
      componentProps: discussion
    });
    return await modal.present();
  }

  async onSaveToCalendar(discussion: Discussions) {
    const date = this.transformTimestampToTime(discussion.Datum);
    const date2 = this.transformTimestampToTime(discussion.Datum + 3600);

    this.calendar.hasReadWritePermission().then((success) => {
      if (!success) {
        this.calendar.requestReadWritePermission().then((scs) => {
          if (!scs) {
            this.service.presentResult('Die App hat keinen Zugriff auf den Kalender', 'danger', 'bottom');
          } else {
            this.calendar.createEventInteractively(discussion.Titel, discussion.Room,
              discussion.Beschreibung, date, date2);
          }
        });
      } else {
        this.calendar.createEventInteractively(discussion.Titel, discussion.Room,
          discussion.Beschreibung, date, date2);
      }
    });
  }

  async onChangeMainContent() {
    await this.loadProgramm(this.loadMainProgramm);
    await this.loadschedule(this.loadMainProgramm);
  }

  async loadschedule(isMainProgramm: boolean) {
    isMainProgramm ? this.firestore.collection(`Hauptprogramm`).valueChanges().subscribe(loadedschedule => {
      this.schedule = loadedschedule;
      this.loadedschedule = loadedschedule;
      this.scheduleLoaded = Promise.resolve(true);
      this.schedule = this.sortSchedule(this.schedule);

    }) : this.firestore.collection('workshops').valueChanges().subscribe(loadedschedule => {
      this.schedule = loadedschedule;
      this.loadedschedule = loadedschedule;
      this.scheduleLoaded = Promise.resolve(true);
      this.schedule = this.sortSchedule(this.schedule);
    });

    /*
    this.service.getDiscussions().subscribe(actionArray => {
      this.schedule = actionArray.map(item => {
        return {
          ...(item.payload.doc.data() as object)
        } as Discussions;
      });
      this.service.getWorkshops().subscribe(acArr => {
      this.helper = acArr.map(item => {
        return {
          ...(item.payload.doc.data() as object)
        } as Discussions;
      });
      this.scheduleLoaded = Promise.resolve(true);
      this.schedule = [].concat(this.schedule, this.helper);
      this.schedule = this.sortArray(this.transform(this.schedule, 'Day'));
  }); });*/
  }
  async loadProgramm(isMainProgramm: boolean) {
    isMainProgramm ? this.service.getDiscussions().subscribe(actionArray => {
      this.discussions = actionArray.map(item => {
        return {
          ...(item.payload.doc.data() as object)
        } as Discussions;
      });
      this.filtersLoaded = Promise.resolve(true);
      this.mainContent = 'Hauptprogramm';
      this.discussions = this.sortArray(this.transform(this.discussions, 'Day'));
      this.setFavoriteProperty(this.discussions);
    }) : this.service.getWorkshops().subscribe(actionArray => {
      this.discussions = actionArray.map(item => {
        return {
          ...(item.payload.doc.data() as object)
        } as Discussions;
      });
      this.filtersLoaded = Promise.resolve(true);
      this.mainContent = 'Workshops';
      this.discussions = this.sortArray(this.transform(this.discussions, 'Day'));
      this.setFavoriteProperty(this.discussions);
    });
    this.slide='overview';

  }

  transformTimestampToTime(dateInSeconds) {
    const date = new Date(0);
    date.setUTCSeconds(dateInSeconds);
    return date;
  }

  getFullHoursMinutesFromDate(date: Date) {
    return (date.getHours() < 10 ? '0' : '') + date.getHours() + ':' + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
  }

  slideChanged() {
    this.slides.getActiveIndex().then(index => {
      switch (index) {
        case 0: this.slide = 'overview'; break;
        case 1: this.slide = 'Thursday'; break;
        case 2: this.slide = 'Friday'; break;
        case 3: this.slide = 'Saturday'; break;
      }
    });
  }


  changeslide(value) {
    this.slides.slideTo(value);
  }

  async onShowRoomDetails(discussion: Discussions) {
    const modal = await this.modalController.create({
      component: RoomPopupPage,
      componentProps: discussion
    });
    return await modal.present();
  }

  onAddToSchedule(discussion) {
    discussion.isCurrentlyFav ? this.removeFromFavorite(discussion) : this.addToFavorite(discussion);
  }

  async removeFromFavorite(discussion) {
    discussion.isCurrentlyFav = false;
    const favArray = await this.service.getStoredItem('favorites');
    if (favArray === null) {
      return;
    }
    favArray.forEach(fav => {
      if (discussion.Titel === fav.Titel) {
        favArray.splice(favArray.indexOf(fav), 1);
      }
    });
    this.service.setStoredItem('favorites', favArray);
    this.refreshPushNotif();
  }

  async addToFavorite(discussion) {
    discussion.isCurrentlyFav = true;
    let favArray = await this.service.getStoredItem('favorites');
    if (favArray === null) {
      favArray = [];
    }
    favArray.push(discussion);
    this.service.setStoredItem('favorites', favArray);
    this.refreshPushNotif();
  }

  async setFavoriteProperty(discussionDays) {
    const favArray = await this.service.getStoredItem('favorites');

    if (discussionDays === null || discussionDays === [] || discussionDays === undefined ||
      favArray === null || favArray === [] || favArray === undefined) {
      return;
    }

    discussionDays.forEach(day => {
      day.value.forEach(discussion => {
        favArray.forEach(fav => {
          if (discussion.Titel === fav.Titel) {
            discussion.isCurrentlyFav = true;
          }
        });
      });
    });
  }

  async refreshPushNotif() {
    this.localNotifications.hasPermission().then(async (success) => {
      if (!success) {
        console.log('No permission');
        this.localNotifications.requestPermission().then(async (scs) => {
          if (!scs) {
            this.service.presentResult('Die App hat keine Berechtigung zum Senden von Benachrichtigungen', 'danger', 'bottom');
          } else {
            await this.localNotifications.cancelAll();
            const favArray = await this.service.getStoredItem('favorites');
            favArray.forEach(fav => {
              const date = new Date(0);
              date.setUTCSeconds(fav.Datum - 1800); // Notification soll halbe Stunde vor Beginn angezeigt werden.
              console.log('Veranstaltungsbenachrichtigung Zeitpunkt: ', date);
              this.localNotifications.schedule({
                text: 'Mannheim Forum: ' + fav.Titel + ' beginnt um ' + date,
                trigger: { at: date },
              });
            });
          }
        });
      } else {
        await this.localNotifications.cancelAll();
        const favArray = await this.service.getStoredItem('favorites');
        favArray.forEach(fav => {
          const date = new Date(0);
          date.setUTCSeconds(fav.Datum - 1800); // Notification soll halbe Stunde vor Beginn angezeigt werden.
          console.log('Veranstaltungsbenachrichtigung Zeitpunkt: ', date);
          this.localNotifications.schedule({
            text: 'Mannheim Forum: ' + fav.Titel + ' beginnt um ' + date,
            trigger: { at: date },
          });
        });
      }
    });
  }
  initializeItems() {
    this.schedule = this.loadedschedule;
  }
  getItems(ev) {
    this.initializeItems();
    const val = ev.target.value;

    // if the value is an empty string don't filter the items
    if (val && val.trim() !== '') {
      this.schedule = this.schedule.filter((schedule) => {
        return ((schedule.Titel + schedule.Beschreibung + schedule.Room).toLowerCase().indexOf(val.toLowerCase()) > -1);
      });
    }
  }


  transform(collection: Array<any>, property: string): Array<any> {
    if (!collection) {
      return null;
    }
    const groupedCollection = collection.reduce((previous, current) => {
      if (!previous[current[property]]) {
        previous[current[property]] = [current];
      } else {
        previous[current[property]].push(current);
      }
      return previous;
    }, {});
    return Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key] }));
  }
}
