import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { News } from '../news';
import { ViewChild } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { NewsPopupPage } from '../news-popup/news-popup.page';
import { IonSlides, AlertController, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-news',
  templateUrl: './news.page.html',
  styleUrls: ['./news.page.scss'],
})
export class NewsPage implements OnInit {

  constructor(
    private service: DataService,
    public loadingController: LoadingController,
    private router: Router,
    private modalController: ModalController
  ) { }
  news: Array<any>;
  filtersLoaded: Promise<boolean>;

  ngOnInit() {
    this.service.getNews().subscribe(actionArray => {
      this.news = actionArray.map(item => {
        return {
          ...(item.payload.doc.data() as object)
        } as News;
      });
      this.filtersLoaded = Promise.resolve(true);
    });
  }
  async onShowNewsDetails(news: News) {
    const modal = await this.modalController.create({
      component: NewsPopupPage,
      componentProps: news
    });
    return await modal.present();
  }

}
