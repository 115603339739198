import { Component, OnInit, Input } from '@angular/core';
import { Rooms } from '../rooms';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { AngularFirestore } from '@angular/fire/firestore';
import { Events } from '@ionic/angular';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-room',
  templateUrl: './room.page.html',
  styleUrls: ['./room.page.scss']
})
export class RoomPage implements OnInit {
  constructor(
    private iab: InAppBrowser,
    private firestore: AngularFirestore,
    public events: Events,
    private navCtrl: NavController,
  ) { }
  discussion: any;
  public roomlist: any[];
  public loadedroomlist: any[];
  rooms: Array<any>;
  link: string;
  raum: any;
  filtersLoaded: Promise<boolean>;

  ngOnInit() {
    this.firestore.collection(`rooms`).valueChanges()
      .subscribe(roomlist => {
        this.roomlist = roomlist;
        this.loadedroomlist = roomlist;
        this.filtersLoaded = Promise.resolve(true);
      });
  }

  initializeItems() {
    this.roomlist = this.loadedroomlist;
  }

  getItems(ev) {
    this.initializeItems();
    const val = ev.target.value;

    // if the value is an empty string don't filter the items
    if (val && val.trim() !== '') {
      this.roomlist = this.roomlist.filter((room) => {
        return ((room.Titel + room.Beschreibung + room.Adresse).toLowerCase().indexOf(val.toLowerCase()) > -1);
      });
    }
  }

  openlink(rooms: Rooms) {
    this.iab.create(rooms.link, '_blank', 'location=yes');
  }

  goback() {
    this.navCtrl.back();
  }
}
